@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
html {
  background-color: #f8f8f9;
}
body {
  background-color: #f8f8f9;
}

#App > div {
  background-color: #f8f8f9;
}

div.root {
  background-color: #f8f8f9;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

.input-blue-border {
  height: 43px;
  max-width: 100%;
  min-width: 50%;
  border-radius: 10px;
  border-width: 1px;
  border-color: #3eb0ca;
  margin-bottom: 10px;
  margin-top: 5px;
}

.modal {
  background-color: rgba(67, 89, 99, 0.64);
  position: fixed;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal_content {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0px;
  padding: 20px;
  margin: 25px;
}

.offer-card {
  background-color: white;
  width: 100%;
  display: flex;
  border-radius: 9px;
  align-self: center;
  padding: 22px;
  box-shadow: 0px 3px 10px -3px #738791;
}

.content-card {
  height: 95%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 3px 10px -3px #738791;
  padding-top: 30px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
  max-width: 50%;
  margin-bottom: 70px;
}

.mobile-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 100%;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 70px;
}

//icons
.white-arrow-icon {
  margin-left: 10px;
  height: 29px;
  width: 29px;
}
.back-blue-icon {
  width: 10px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}

//text
.white-header-text {
  color: white;
  font-family: 'Nunito';
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  text-align: right;
}

.white-text-in-button {
  color: #ffffff;
  font-family: 'Nunito';
  font-size: 18px;
  font-weight: bold;
}

.input-title {
  color: #435963;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
}

.filter-blue-title {
  color: #3eb0ca;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
}

.red-title {
  color: #f4511e;
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: bold;
}

.common-text-bold {
  color: #435963;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
}

.common-large-text-bold {
  color: #435963;
  font-family: 'Nunito';
  font-size: 20px;
  font-weight: bold;
}

.common-text-regular {
  color: #435963;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  white-space: pre-line;
}

.bunner-blue-title {
  color: #3eb0ca;
  font-family: 'Nunito';
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.bunner-gray-text {
  color: #435963;
  font-family: 'Nunito';
  font-size: 16px;
  font-weight: bold;
  text-align: center;

}
.bunner-card {
  background-color: #E9F1F4;
  width: 100%;
  display: flex;
  border-radius: 9px;
  align-self: center;
  padding: 30px;
  box-shadow: 0px 3px 10px -3px #738791;
}
//button

.blue-button {
  border-radius: 25px;
  margin-bottom: 30px;
  width: 100%;
  min-height: 50px;
  background-color: #4FC0CF;
}

.secondary-blue-button {
  height: 50px;
  width: 40%;
  border-radius: 25px;
  background-color: #307F92;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  cursor: pointer;
}

.main-blue-button {
  height: 33px;
  width: 80%;
  border-radius: 20px;
  background-color: #307F92;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
}

.round-button {
  display: flex;
  width: 130px;
  min-width: 130px;
  margin: 5px;
  border-radius: 25px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 10px -3px #979797;
  cursor: pointer;
}
